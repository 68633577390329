import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import {DocsType, LeaveRequestPayload, PaginatedResponse, SPLITOBJ} from "../Shared/Interfaces";
import {Board, Boards} from "./candidate/candidate";

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  serviceUrl: string = "finch-service"
  baseUrl: string = `${environment.url}/${this.serviceUrl}`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient) {
  }

  createEmployee(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/employee`, payload, this.httpOptions);
  }

  createHoliday(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/holiday-calendar`, payload, this.httpOptions);
  }

  getEmployee(size: number, pageNumber: number, search: string, includeInactive: boolean, sort: string): Observable<PaginatedResponse<any>> {
    let params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', size.toString())
      .set('query', search)
      .set('includeInactive', includeInactive.toString());

    if (sort) {
      params = params.set('sort', sort);
    }

    return this.http.get<PaginatedResponse<any>>(`${this.baseUrl}/admin/employee`, {
      params: params,
    });
  }


  getEmployeeForPerformance(size: number, pageNumber: number, search: string, includeInactive: boolean): Observable<any> {
    // return this.http.get<any>(`${this.baseUrl}/admin/allemployees`, {
    //   params: new HttpParams()
    //     .set('page', pageNumber)
    //     .set('size', size)
    //     .set('query', search)
    //     .set('includeInactive', includeInactive)
    // },);
    return this.http.get<any>(`${this.baseUrl}/admin/allemployees`, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
        .set('query', search)
        .set('includeInactive', includeInactive)
    },);

  }

  getEmployeeStepperDetails(employeePerformanceId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/kpi-data/active`, {
      params: new HttpParams().set('employeePerformanceId', employeePerformanceId)
    });
  }


  getEmployeeDetails(empId: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employeePerformance/${empId}`, {
      params: new HttpParams()

    },);
  }

  saveKpiDetails(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/performance-tracker/kpi-details`, payload, this.httpOptions);
  }

  saveKpiSummary(employeePerformanceId: any, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/performance-tracker/update-summary/${employeePerformanceId}`, payload, this.httpOptions);
  }

  getAppraisalsSummary(employeePerformanceId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/kpi-details/appraisals`, {
      params: new HttpParams().set('employeePerformanceId', employeePerformanceId)
    });
  }

  startAppraisal(empId: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/employee/${empId}/appraisal-start`, this.httpOptions);
  }

  getAllManagers(query?: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee`, {
      params: new HttpParams()
        .set('query', query)
    });
  }

  getHolidays(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/holiday-calendar`)
  }

  getTodayAttendance(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/attendance/today`)
  }

  getOneEmployee(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${id}`, this.httpOptions);
  }

  getHolidayById(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/holiday-calendar/${id}`, this.httpOptions);
  }

  deleteHolidayById(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/admin/holiday-calendar/${id}/delete`, this.httpOptions);
  }

  deleteDocumentById(id: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/documents/${id}/trash`, this.httpOptions);
  }

  deleteImageById(employeeImageId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/employee-image/${employeeImageId}/trash`, this.httpOptions);
  }

  deleteDocumentTypeById(id: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/document-type/${id}/trash`, this.httpOptions);
  }

  updateHolidayById(id: string, date: string, description: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/holiday-calendar/${id}`,
      {
        "holidayDate": date,
        "description": description
      });
  }

  updateEmployee(id: string, payload: any): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/employee/${id}`, payload).toPromise();
  }

  createPersonalInfo(employeeId: string, payload: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/employee/${employeeId}/info`, payload, this.httpOptions).toPromise();
  }

  getPersonalInfo(employeeId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/employee/${employeeId}/info`, this.httpOptions);
  }

  getGitLogsByUserId(employeeId: string, fromDate: string, toDate: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employees/${employeeId}/checkin-records`, {
      params: new HttpParams()
        .set('fromDate', fromDate)
        .set('toDate', toDate)
    });
  }

  updateIdentity(employeeId: string, identityId: string, payload: any): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/employee/${employeeId}/info/${identityId}`, payload, this.httpOptions).toPromise();
  }

  getAttendanceOfAll(payload: any, size: number, pageNumber: number,sort: string): Observable<any> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('size', size)
    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http.post<any>(`${this.baseUrl}/admin/summary`, payload,
      {
      params: params,
    });

  }



  createAddress(employeeId: string, payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/employee/${employeeId}/address`, payload, this.httpOptions);
  }


  getAddress(employeeId: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${employeeId}/address`, this.httpOptions);
  }

  updateAddress(employeeId: any, addressId: any, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/employee/${employeeId}/address/${addressId}`, payload, this.httpOptions)
  }

  getOneAddress(employeeId: any, addressId: any): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${employeeId}/address/${addressId}`, this.httpOptions).toPromise();
  }

  getAllLeave(payload: any,size: number, pageNumber: number,sort: string): Observable<any> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('size', size)
    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http.post<any>(`${this.baseUrl}/admin/leave`, payload,
      {
        params: params,
      });
  }

  getEmployeeProfile(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/me`, this.httpOptions).toPromise();
  }

  getEmployeePerformanceProfile(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/employee-performance/me`, this.httpOptions).toPromise();
  }

  getRxEmployeeProfile(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/me`, this.httpOptions);
  }

  getDocumentType(): Observable<DocsType[]> {
    return this.http.get<DocsType[]>(`${this.baseUrl}/document-type`);
  }

  addDocumentType(payload: any): Promise<any> {
    return this.http.post(`${this.baseUrl}/document-type`, payload).toPromise();
  }

  uploadDocuments(data: FormData, employeeId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/employees/${employeeId}/documents/upload`, data, {
      reportProgress: true,
      observe: 'events'
    })
  }

  uploadAssetImage(data: FormData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/image`, data)
  }

  uploadImage(data: FormData, employeeId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/employee/${employeeId}/upload-image`, data, {
      reportProgress: true,
      observe: 'events'
    })
  }

  downloadDocuments(employeeId: string, documentId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/employees/${employeeId}/documents/${documentId}/download`, {
      reportProgress: true,
      observe: 'events'
    })
  }

  downloadImage(employeeId: string, employeeImageId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/employees/${employeeId}/employee-images/${employeeImageId}/download`, {
      reportProgress: true,
      observe: 'events'
    })
  }

  uploadProfileImage(data: FormData, employeeId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/employee/${employeeId}/profile-picture/upload`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getUserHolidays(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/holiday-calendar`)
  }

  createAccount(employeeId: string, payload: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/employees/${employeeId}/ledgers/accounts`, payload, this.httpOptions).toPromise();
  }

  fetchAccount(employeeId: string): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/employees/${employeeId}/ledgers/accounts`, this.httpOptions).toPromise();
  }

  updateEmployeeManager(employeeId: string, managerId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/employee/${employeeId}/manager`, {managerId: managerId}, {
      reportProgress: true,
      observe: 'events'
    })
  }

  removeEmployeeManager(employeeId: string) {
    return this.http.delete<any>(`${this.baseUrl}/employee/${employeeId}/manager`, this.httpOptions)
  }

  getEmoloyeeLeaves(employeeId: string, size: number, pageNumber: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${employeeId}/leave`, {
      params: new HttpParams()
        .set('size', size)
        .set('page', pageNumber)
    })
  }
  getEmoloyeeLeavesForYear(employeeId: string, size: number, pageNumber: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${employeeId}/one-year/leave`, {
      params: new HttpParams()
        .set('size', size)
        .set('page', pageNumber)
    })
  }

  applyEmployeeLeave(employeeId: string, payload: LeaveRequestPayload): Observable<SPLITOBJ> {
    return this.http.post<SPLITOBJ>(`${this.baseUrl}/employee/${employeeId}/leave`, payload, this.httpOptions)
  }

  getSplitLeave(fromDate: string, toDate: string): Observable<any> {
    const dateRange = new HttpParams({fromObject: {fromDate: fromDate, toDate: toDate}});
    return this.http.get<any>(`${this.baseUrl}/leave/split`, {params: dateRange});
  }

  getEmployeeLeavesDetails(pageNumber: number = 0, size: number = 1, sort: string[] = []): Observable<any> {
    const additionalParams = new HttpParams({fromObject: {page: pageNumber, size: size, sort: sort}});
    return this.http.get<any>(`${this.baseUrl}/employee/on-leave`, {params: additionalParams}).pipe(
      catchError(this.handleError)
    );
  }
  getleaveType(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/leaves/filter/leave-type`);
  }


  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error();
    return throwError(() => errorMessage);
  }

  addAccountTransactions(accountId: string, payload: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/ledgers/accounts/${accountId}/transactions`, payload, this.httpOptions).toPromise();
  }

  fetchAccountTransactions(accountId: String, pageNumber: number, size: number): Observable<PaginatedResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/ledgers/accounts/${accountId}/transactions`, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
    })
  }

  getAssignedLeaves(size: number, pageNumber: number, state?: string[]|any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/leave/assigned/me`, {
      params: new HttpParams()
        .set('size', size)
        .set('page', pageNumber)
        .set('state', state)
    });
  }

  createNotice(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/notice`, payload, this.httpOptions);
  }

  fetchNotice(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/notices`, this.httpOptions);
  }

  fetchOneNotice(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/notice/${id}`);
  }

  editNotice(id: any, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/notice/${id}`, payload, this.httpOptions);
  }

  deleteNotice(id: any): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/admin/notice/${id}`, this.httpOptions);
  }

  getEmployeeNotice(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/notices`);
  }

  getEmployeeNotificationFeeds(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/feeds`);
  }

  getOffice(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/office`);
  }

  updateEmployeeOffice(employeeId: any, officeId: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/employee/${employeeId}/office/${officeId}`, this.httpOptions);
  }

  getEmployeeChart(employeeId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee/${employeeId}/candle-stick-chart`, this.httpOptions);
  }

  getLeaveData(employeeId: string, leaveId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee/${employeeId}/leave/${leaveId}`, this.httpOptions);
  }

  approveLeave(leaveId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/leave/${leaveId}/approve`, this.httpOptions);
  }

  denyLeave(leaveId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/leave/${leaveId}/reject`, this.httpOptions);
  }

  cancelLeave(leaveId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/leave/${leaveId}/cancel`, this.httpOptions);
  }

  getEmpStatus(employeeId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${employeeId}/summary`);
  }

  saveEmergencyContact(employeeID: string, payload: any) {
    return this.http.post<any>(`${this.baseUrl}/admin/employee/${employeeID}/emergency-contact`, payload, this.httpOptions);
  }

  updateEmergencyContact(payload: any, contactId: any) {
    return this.http.put<any>(`${this.baseUrl}/employee/emergency-contact/${contactId}`, payload, this.httpOptions)
  }

  getEmployeeEmergencyContacts(): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee/emergency-contact`, this.httpOptions);
  }

  getEmployee1EmergencyContacts(employeeId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee/${employeeId}/emergency-contact`, this.httpOptions);
  }

  deleteEmergencyContact(contactId: any): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/employee/emergency-contact/${contactId}`, this.httpOptions);
  }

  getDevice(employeeId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/employee/${employeeId}/devices`, this.httpOptions);
  }

  deleteDevice(userId: any): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/employee/${userId}/devices`, this.httpOptions);
  }

  getEmployeeStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee/ledger/account`, this.httpOptions);
  }

  getCompOff(filter: any, size: number, pageNumber: number, sort: any,): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/comp-off-requests/assigned/v2`, {
      params: new HttpParams()
        .set('compOffStates', filter)
        .set('page', pageNumber)
        .set('size', size)
        .set('query', sort)
    })
  }

  getSingleEmployeeCompOff(): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee/comp-off-requests`, this.httpOptions);
  }

  getEmployeeDocuments(employeeId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employees/${employeeId}/documents`, this.httpOptions)
  }

  getEmployeeImages(employeeId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/${employeeId}/images`, this.httpOptions)
  }

  getEmployeeEvents(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/events`, this.httpOptions)
  }

  deleteEmployee(employeeId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/employee/${employeeId}`, this.httpOptions)
  }

  addCandidate(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/candidate`, payload, this.httpOptions);
  }

  getAllCandidates(size: number, pageNumber: number, search: string, status: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/candidate/search`, {
      "query": search,
      "status": status,
    }, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
    });
  }

  updateCandidate(candidateId: string, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}`, payload, this.httpOptions);
  }

  getCandidateDetails(candidateId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/candidate/${candidateId}`, this.httpOptions);
  }

  getCandidateDesignationDetails(candidateId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/candidate/${candidateId}/designation`, this.httpOptions);
  }

  getCandidateDocumentDetails(candidateId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/candidate/${candidateId}/documents`, this.httpOptions);
  }

  updateCandidateStatus(candidateId: string, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/status`, payload, this.httpOptions);
  }

  updateBoardToCandidate(candidateId: number, boardId: number): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidates/${candidateId}/boards`, {boardId: boardId}, this.httpOptions);
  }

  updateCandidateFeedback(candidateId: string, payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/candidate/${candidateId}/feedback`, payload, this.httpOptions)
  }

  getCandidateFeedback(candidateId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/candidate/${candidateId}/feedback`, this.httpOptions)
  }

  uploadCandidateResume(data: FormData, candidateId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/candidate/${candidateId}/resume/upload`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateCandidateExperience(data: any, candidateId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/experience`, data, this.httpOptions);
  }

  updateCandidateDesignation(data: any, candidateId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/designation`, data, this.httpOptions);
  }

  updateCandidateQualification(qualification: string, candidateId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/qualification`, {}, {
      params: new HttpParams()
        .set('qualification', qualification)
    })
  }

  updateCandidateLocation(location: string, candidateId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/location`, {}, {
      params: new HttpParams()
        .set('location', location)
    })
  }

  updateCandidateCTC(currentCTC: string, expectedCTC: string, candidateId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/ctc`, {}, {
      params: new HttpParams()
        .set('currentCTC', currentCTC)
        .set('expectedCTC', expectedCTC)
    })
  }

  updateCandidateSkills(skills: string, candidateId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}/skills`, {}, {
      params: new HttpParams()
        .set('skills', skills)
    })
  }

  updateCompOffState(id: string, payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/comp-off-request/${id}`, payload, this.httpOptions)
  }

  updateCandidateDetails(candidateId: string, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate/${candidateId}`, payload);
  }

  updateInterveiewer(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/interview`, payload);
  }

  checkInUser(employeeId: string, payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/employee/${employeeId}/checkIn`, payload, this.httpOptions)
  }

  fetchAllCredentials(size: string, pageNumber: number, search?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/credentials`, {
      params: new HttpParams()
        .set('query', search)
        .set('page', pageNumber)
        .set('size', size)
    })
  }

  fetchOneCredential(id: String): Observable<any> {
    return this.http.get(`${this.baseUrl}/credential/${id}`, this.httpOptions)
  }

  updateCredential(id: string, payload: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/credential/${id}`, payload, this.httpOptions);
  }

  createBankInfo(employeeId: string, payload: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/employee/${employeeId}/bankInfo`, payload, this.httpOptions).toPromise();
  }

  getBankInfo(employeeId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/employee/${employeeId}/bankInfo`, this.httpOptions);
  }

  updateBankInfo(employeeId: string, infoId: string, payload: any): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/employee/${employeeId}/bankInfo/${infoId}`, payload, this.httpOptions).toPromise();
  }

  suspendUser(id: string): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/employee/${id}/suspend`, this.httpOptions).toPromise();
  }

  restoreSuspendedUser(id: string): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/employee/${id}/restore`, this.httpOptions).toPromise();
  }

  getSalaryInfo = (employeeId: string): Observable<any> => {
    return this.http.get<any>(`${this.baseUrl}/admin/employee/${employeeId}/salary`, this.httpOptions);
  }

  deleteCommentById(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/feedback/${id}`, this.httpOptions);
  }

  updateComment(data: any, feedbackId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/feedback/${feedbackId}`, data, this.httpOptions);
  }

  markScrumAbsent(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/scrum-attendance/absent`, payload, this.httpOptions);
  }

  getAllScrumAbsentees(payload: any, size: number, pageNumber: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/scrum-attendance/absentees`, payload,
      {
        params: new HttpParams()
          .set('page', pageNumber)
          .set('size', size)
      });
  }

  getAllEmployeesForScrum(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/employee/all`, this.httpOptions)
  }

  getAllAttendanceLogs(size: number, pageNumber: number, sort: any,): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/attendance-logs`, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
        .set('query', sort)
    });
  }

  createSalaryInfo(employeeId: string, payload: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/employee/${employeeId}/salary`, payload, this.httpOptions).toPromise();
  }

  updateSalaryInfo(employeeId: string, salaryId: string, payload: any): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/employee/${employeeId}/salary/${salaryId}`, payload, this.httpOptions).toPromise();
  }

  getAllBoards(): Observable<Board[]> {
    return this.http.get<Board[]>(`${this.baseUrl}/boards`);
  }

  checkInEmployee(employeeId: string, payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/restricted/employee/${employeeId}/checkIn`, payload, this.httpOptions)
  }

  checkOutEmployee(employeeId: string, payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/restricted/employee/${employeeId}/checkOut`, payload, this.httpOptions)
  }

  getAllDesignations(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/designations`);
  }

  getEmployeeStatistics(): Promise<any> {
    return this.http.get<any []>(`${this.baseUrl}/admin/employee/stats`).toPromise();
  }

  applyEmployeeCompOff(payload: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/comp-off-requests`, payload, this.httpOptions).toPromise();
  }

  getInterviewerList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/interviewers`);
  }

  getOfficeLocation(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/office`);
  }

  getCandidateInterviewerDetails(candidateId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/candidate/${candidateId}/interview`, this.httpOptions);
  }

  deleteInterviewRound(interviewId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}//interview/${interviewId}`)
  }

  cancelInterview(interviewId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/interview/${interviewId}/cancel`, '');
  }

  deleteResume(candidateId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/candidate/${candidateId}/resume/remove`, this.httpOptions);
  }

  interviewRoundFeedback(candidateId: string, interviewRoundId: string, payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/candidate/${candidateId}/interview-round/${interviewRoundId}/feedback`, payload, this.httpOptions)
  }

  getInterviewRoundFeedback(candidateId: string, interviewRoundId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/candidate/${candidateId}/interview-round/${interviewRoundId}/feedback`, this.httpOptions)
  }

  updateCandidateComment(candidateFeedbackId: string, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/candidate-feedback/${candidateFeedbackId}`, payload, this.httpOptions);
  }

  deleteInterviewCommentById(candidateFeedbackId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/candidate-feedback/${candidateFeedbackId}`, this.httpOptions);
  }

  getStatsForChart(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employees/stats`, this.httpOptions)
  }

  createAsset(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin/inventory`, payload, this.httpOptions);
  }

  updateAsset(assetId: number, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/inventory/${assetId}`, payload, this.httpOptions);
  }

  getAssets(size: number, pageNumber: number, search: string,): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/inventory`, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
        .set('query', search)
    })
  }

  getAssetsById(assetId: number,): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/inventory/${assetId}`, this.httpOptions);
  }

  assignAsset(assetId: number, employeeId: number): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/admin/inventory/${assetId}/assign`, {}, {
      params: new HttpParams()
        .set('employeeId', employeeId)
    })
  }

  getAssetsHistory(assetId: number,size: number, pageNumber: number,): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/inventory/${assetId}/history`, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
    });
  }

  getAllEmployee(search: string, includeInactive: boolean): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin/all-employee`, {
      params: new HttpParams()
        .set('query', search)
        .set('includeInactive', includeInactive)
    },);
  }

  updateEmployeeStatusToCompleted(employeePerformanceId: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${employeePerformanceId}/status/completed`, {

    });
  }
  updateKpiDetails(id: any, payload: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/appraisal-detail/${id}`, payload);
  }

  deleteTransactions = (transactionId: string): Observable<any[]> => {
    return this.http.delete<any[]>(`${this.baseUrl}/ledger-transactions/${transactionId}`);
  }

  getFeedBack(size: number, pageNumber: number, sort: any,): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/employee/feedback`, {
      params: new HttpParams()
        .set('page', pageNumber)
        .set('size', size)
        .set('query', sort)
    });
  }
}
